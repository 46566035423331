import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Box,
    CircularProgress,
    Stack,
    Tooltip
} from "@mui/material";
import { organizationsApi, robotsApi } from "api";
import AvatarRem from './AvatarRem';
import { PrecisionManufacturing, Search, Add as AddIcon, Check } from '@mui/icons-material';
import { Robot } from 'hooks/useRobots';
import Close from '@mui/icons-material/Close';
import useBreakpoint from 'hooks/useBreakpoint';
import InfoIcon from '@mui/icons-material/Info';
import { useInfiniteScroll, useSelectedOrganization, useSnackbar, useTranslations } from 'hooks';
import RobotDetails from './RobotDetails';
import { getTranslation } from 'common';

interface RobotsShopProps {
    open: boolean;
    onClose: () => void;
    onRobotUpdate?: () => Promise<void>;
    organizationId: number | null | undefined;
}

interface Category {
    id: number;
    description: string;
}

const INITIAL_PAGE = 1;
const PAGE_SIZE = 10;

const RobotsShop: React.FC<RobotsShopProps> = ({ open, onClose, onRobotUpdate, organizationId }) => {
    const [robots, setRobots] = useState<Robot[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState<number>(INITIAL_PAGE);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRobot, setSelectedRobot] = useState<Robot | undefined>(undefined);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const isMobile = useBreakpoint("down", "sm");
    const lastRobotElementRef = useInfiniteScroll(setPage, loading, hasMore);
    const organization = useSelectedOrganization();
    const { sendSnack } = useSnackbar();
    const translations = useTranslations();

    const [categories, setCategories] = useState<Category[]>([]);

    const getCategoryDescription = (id: number) => {
        const category = categories.find(category => category.id === id);
        return category ? category.description : '';
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const { data } = await robotsApi.get('/categories');
                if (data) {
                    setCategories(data.categories);
                }
            } catch {
                setCategories([]);
            }
        };
        fetchCategories();
    }, []);

    const getFirstRobots = useCallback(async () => {
        setLoading(true);
        try {
            const queryPage = `?page=${INITIAL_PAGE}&pagesize=${PAGE_SIZE}`;
            const filter = `&p=${searchTerm}`;
            const { data } = await robotsApi.get(`/shops${queryPage}${filter}`);
            setHasMore(data.pagination.curr_page < data.pagination.total_pages);
            setRobots([...data.robots.filter((robot: Robot) => robot.published)]);
            setPage(INITIAL_PAGE);
        } catch {
        } finally {
            setLoading(false);
        }
    }, [searchTerm]);

    const getRobots = useCallback(async (page: number, searchTerm: string, pageSize: number = PAGE_SIZE) => {
        setLoading(true);
        try {
            const queryPage = `?page=${page}&pagesize=${pageSize}`;
            const filter = `&p=${searchTerm}`;
            const { data } = await robotsApi.get(`/shops${queryPage}${filter}`);
            setHasMore((data.pagination.curr_page) < (data.pagination.total_pages));
            //GPT (errore di duplicati, dovrebbe andare bene così)
            setRobots((prevRobots) => [
                ...prevRobots.filter((robot) => !data.robots.some((newRobot: { id: number; }) => newRobot.id === robot.id)),
                ...data.robots.filter((robot: Robot) => robot.published)
            ]);
        } catch { }
        finally {
            setLoading(false);
        }
        //  }
    }, []);

    useEffect(() => {
        if (open) {
            getFirstRobots();
        }
    }, [open, getFirstRobots]);

    const searchTermRef = useRef("")

    useEffect(() => {
        setPage(INITIAL_PAGE);
        getRobots(INITIAL_PAGE, searchTerm);
    }, [searchTerm, getRobots]);

    useEffect(() => {
        if (searchTerm === searchTermRef.current) {
            getRobots(page, searchTerm);
        }
        return () => {
            searchTermRef.current = searchTerm
        }
    }, [page, getRobots, searchTerm]);

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const { search } = Object.fromEntries(formData)
        setSearchTerm(search as string)
    }

    const handleOpenDetails = (robot: Robot) => {
        setSelectedRobot(robot);
        setDetailsOpen(true);
    };

    const handleCloseDetails = () => {
        setDetailsOpen(false);
        setSelectedRobot(undefined);
    };

    const handleOpenAddDialog = (robot: Robot) => {
        setSelectedRobot(robot);
        setAddDialogOpen(true);
    };

    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
        setSelectedRobot(undefined);
    };

    const handleAddRobot = async () => {
        setLoading(true);
        try {
            const response = await organizationsApi.put(`${organization?.id}/robots/${selectedRobot?.id}`);
            sendSnack({
                message: response.data.responsemessage,
                type: 'success',
            });
            if (onRobotUpdate) {
                await onRobotUpdate();
            }
            getRobots(page, searchTerm, page * PAGE_SIZE);
            handleCloseAddDialog();
        } catch { } finally {
            setLoading(false);
        }
    }

    const isRobotInOrganization = (robot: Robot) => {
        return robot.organizations?.some(org => org.name === organization?.name);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen={isMobile}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">
                            {getTranslation(translations, "robots.shop")}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2, width: "25%" }} component="form" onSubmit={handleOnSubmit}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder={getTranslation(translations, "robots.search")}
                                name="search"
                                // value={searchTerm}
                                // onChange={handleSearchChange}
                                // InputProps={{
                                //     startAdornment: (
                                //         <InputAdornment position="start">
                                //             <Search />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                sx={{ flex: 1 }}
                            />
                            <Button variant="contained" type="submit">
                                <Search />
                            </Button>
                            <Button onClick={onClose} color="primary" startIcon={<Close />} />
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers sx={{ height: "600px" }}>
                    {robots.length === 0 ? (
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                            {getTranslation(translations, "no.robots_found")}
                        </Typography>
                    ) : (
                        <Grid container spacing={2}>
                            {robots.map((robot, idx) => (
                                <Grid
                                    item
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xs={12}
                                    key={`robot-${robot.id}`}
                                    ref={robots.length === idx + 1 ? lastRobotElementRef : null}
                                >
                                    <Card
                                        elevation={3}
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            borderRadius: "15px",
                                        }}
                                    >
                                        <CardHeader
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            avatar={
                                                <AvatarRem
                                                    sx={{ width: 60, height: 60 }}
                                                    icon={robot.picture || undefined}
                                                >
                                                    <PrecisionManufacturing sx={{ width: '50%', height: '50%' }} />
                                                </AvatarRem>
                                            }
                                            title={<Typography variant="h6" sx={{ maxWidth: 100, textOverflow: 'ellipsis', textWrap: 'wrap', overflow: "hidden" }}>{robot.name}</Typography>}
                                            action={
                                                <Box>
                                                    <Tooltip title={getTranslation(translations, "robot.details.tooltip")}>

                                                        <IconButton
                                                            aria-label="details"
                                                            onClick={() => handleOpenDetails(robot)}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={getTranslation(translations, isRobotInOrganization(robot) ? "robot.already_in_org" : "generic.add")}>
                                                        <span>
                                                            <IconButton
                                                                aria-label="add"
                                                                onClick={() => handleOpenAddDialog(robot)}
                                                                disabled={isRobotInOrganization(robot)}
                                                            >
                                                                {isRobotInOrganization(robot) ? <Check color='success' /> : <AddIcon />}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                            }
                                        />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography variant="subtitle2" fontWeight="bold">
                                                {(robot.user?.firstname || '') + " " + (robot.user?.lastname || '')}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" fontWeight="bold">
                                                {robot.description}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {robot.idcategory ? `${getCategoryDescription(robot.idcategory)}` : ""}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {loading && (
                        <Stack alignItems="center">
                            <CircularProgress />
                        </Stack>
                    )}
                </DialogContent>
            </Dialog >

            <RobotDetails
                open={detailsOpen}
                onClose={handleCloseDetails}
                robot={selectedRobot}
                onRobotUpdate={() => getRobots(page, searchTerm, page * PAGE_SIZE)}
            />
            {/*DIALOG DI CONFERMA */}
            <Dialog open={addDialogOpen} onClose={handleCloseAddDialog}>
                <DialogTitle>{getTranslation(translations, "generic.button.confirm")}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {getTranslation(translations, "generic.add")} <span style={{ fontWeight: "bold" }}>{selectedRobot?.name}</span> {getTranslation(translations, "generic.to")} <span style={{ fontWeight: "bold" }}>{organization?.name}</span>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">{getTranslation(translations, "generic.button.cancel")}</Button>
                    <Button onClick={handleAddRobot} color="primary" variant="contained" disabled={loading}>
                        {getTranslation(translations, "generic.add")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RobotsShop;